/* =======================================================
   Template Information:
   Template Name: MSXP Connect
   Version: 1.0
   =======================================================

/* =======================================================
   Developer Information:
   Agency: Planisio
   Contact Email: info@planisio.com
   Website: https://planisio.com
   =======================================================
*/

/* ------------------------------ */
/* STARTING: ROOT VARIABLES */
/* ------------------------------ */

:root {
	--primary-color: #374F56;
	--primary-color-hover: #57717d;
	--primary-color-active: #2d444b;
	--secondary-color: #1E2B2F;
	--secondary-color-hover: #2f4247;
	--secondary-color-active: rgb(26, 67, 65);
	--primary-theme-color: #FFFFFF;
	--secondary-theme-color: #F1F2F3;
	--text-color: #374F56;
	--border-color: #E1E7F1;
	--success-color: #37CB8D;
	--font-size-xxs: 0.644rem;
	--font-size-xs: calc(var(--font-size-xxs) * 1.20);
	--font-size-sm: calc(var(--font-size-xxs) * 1.24);
	--font-size-md: calc(var(--font-size-xxs) * 1.4457);
	--font-size-lg: calc(var(--font-size-xxs) * 1.50);
	--font-size-xl: calc(var(--font-size-xxs) * 1.58);
	--font-size-xxl: calc(var(--font-size-xxs) * 1.68);
	--padding: 20px;
	--border-radius: 2px;
	--bs-border-radius-lg: var(--border-radius);
	--content-padding: var(--padding);
	--bs-body-color: var(--text-color);
	--bs-body-bg: var(--primary-theme-color);
	--bs-border-color: var(--border-color);
	--bs-border-color-translucent: var(--border-color);
	--bs-emphasis-color: var(--text-color);
	--bs-link-color: var(--primary-color);
	--bs-navbar-active-color: var(--primary-color);
}

/* ------------------------------ */
/* ENDING: ROOT VARIABLES */
/* ------------------------------ */

/* ------------------------------ */
/* STARTING: FONTS */
/* ------------------------------ */

@font-face {
	font-family: "font-regular";
	font-display: swap;
	src: url("../fonts/DMSans-Regular.ttf");
}

@font-face {
	font-family: "font-medium";
	font-display: swap;
	src: url("../fonts/DMSans-Medium.ttf");
}

@font-face {
	font-family: "font-semi-bold";
	font-display: swap;
	src: url("../fonts/DMSans-SemiBold.ttf");
}

/* ------------------------------ */
/* ENDING: FONTS */
/* ------------------------------ */

/* ------------------------------ */
/* STARTING: LOADING PAGE */
/* ------------------------------ */

body,
.body {
	opacity: 1;
	transition: opacity 0.4s ease-in;
 }
 
 body.ready,
 .body.ready {
	opacity: 1;
 }
 
 /* ------------------------------ */
 /* ENDING: LOADING PAGE */
 /* ------------------------------ */

/* ------------------------------ */
/* STARTING: DEFAULT STYLES */
/* ------------------------------ */

html,
body,
.body {
    margin: 0;
    height: 100%;
    scroll-behavior: smooth;
}

body {
	background-color: var(--primary-theme-color);
	display: flex;
	flex-direction: column;
	align-items: stretch;
	color: var(--text-color);

}

body,
.body {
    display: flex;
    flex-direction: column;
}

main {
    flex-grow: 1;
}

header, main, footer {
    flex-shrink: 0;
}

header,
footer {
	background-color: var(--secondary-theme-color);
}

main {
	flex: 1;
}

.thumb,
section >.content,
.section >.content,
.section,
section {
	padding: 3.2rem 0 3.2rem 0;
	overflow-x: hidden;
}

.section-label {
	width: 100%;
	max-width: 840px;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 1.8rem;
}

.container {
	max-width: 1200px;
}

.lucide {
	color: var(--text-color);
	flex-shrink: 0;
}

.waves,
.waves svg {
	width: 100%;
	display: flex;
}

.waves svg {
	height: 100%;
}

.waves svg path {
	fill: var(--secondary-theme-color);
}

[class*="border-"] {
	border-color: var(--border-color) !important;
}

.logo {
	width: 100%;
	max-width: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.logo.xs {
	max-width: 24px;
}

.logo.md {
	max-width: 36px;
}

.logo.lg {
	max-width: 46px;
}

.logo.xl {
	max-width: 60px;
}

.logo.xxl {
	max-width: 80px;
}

.logo.fixed {
	width: 134px;
}

.logo img {
	width: 100%;
	height: 100%;
}

.font-regular {
	font-family: "font-regular" !important;
}

.font-medium {
	font-family: "font-medium" !important;
}

.font-semi-bold {
	font-family: "font-semi-bold" !important;
}

.font-size-xs {
	font-size: var(--font-size-xs) !important;
}

.font-size-sm {
	font-size: var(--font-size-sm) !important;
}

.font-size-md {
	font-size: var(--font-size-md) !important;
}

.font-size-lg {
	font-size: var(--font-size-lg) !important;
}

.font-size-xl {
	font-size: var(--font-size-xl) !important;
}

.font-size-xxl {
	font-size: var(--font-size-xxl) !important;
}

.thumb .waves {
    bottom: 2.5px;
}

.waves {
    position: relative;
}

.waves img {
    width: 100%;
}

.waves svg,
.waves {
    width: 100%;
    height: auto;
}

.waves path {
    fill: var(--secondary-theme-color) !important;
}

.text-color {
	color: var(--text-color) !important;
}

.text-color-navy-blue {
	color: var(--navy-blue-color) !important;
}

.text-primary-color {
	color: var(--primary-color) !important;
}

.icon-color {
	stroke: var(--text-color) !important;
}

.bg-primary {
	background-color: var(--primary-color) !important;
}

.bg-secondary {
	background-color: var(--secondary-color) !important;
}

.bg-primary-tc {
	background-color: var(--primary-theme-color) !important;
}

.bg-secondary-tc {
	background-color: var(--secondary-theme-color) !important;
}

.bg-text-color {
	background-color: var(--text-color) !important;
}

.bg-secondary-tc-half-column {
	background: linear-gradient(to bottom, var(--secondary-theme-color) 50%, #fff 50%);
}

.w-mc {
	max-width: max-content;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "font-medium";
	letter-spacing: 1px;
	line-height: 1.899;
}

.h1 {
	font-size: calc(var(--font-size-xxl) * 1.40) !important;
}

.h2 {
	font-size: calc(var(--font-size-xxl) * 1.20) !important;
}

.h3 {
	font-size: calc(var(--font-size-xxl) * 1.18) !important;
}

.h4 {
	font-size: calc(var(--font-size-xxl) * 1.14) !important;
}

.h5 {
	font-size: calc(var(--font-size-xxl) * 1.12) !important;
}

.h6 {
	font-size: calc(var(--font-size-xxl) * 1.08) !important;
}

p,
span,
a,
li,
label {
	font-family: var(--font-regular);
	font-size: var(--font-size-lg);
	line-height: 1.888;
}

span {
	display: block;
}

cite {
	display: inline-block;
	line-height: normal;
	font-style: normal;
	color: var(--primary-color);
}

a {
	text-decoration: none;
	color: var(--primary-color);
	transition: color 0.2s ease-in-out;
}

a:hover {
	color: var(--primary-color-hover);
}

a:active {
	color: var(--primary-color-active);
}

strong {
	font-family: "font-medium";
	font-weight: normal;
	color: var(--text-color);
}

.text-success {
	color: var(--success-color) !important;
}

.dominant-hover-color,
.dominant-hover-color > * {
    transition: color 0.2s ease-in-out;
}

.dominant-hover-color:hover,
.dominant-hover-color:hover > * {
    color: var(--primary-color-hover) !important;
}

.dominant-hover-color:active,
.dominant-hover-color:active > * {
    color: var(--primary-color-hover) !important;
}

.text-bg-color {
    --bs-badge-color: var(--text-color);
	border: 1px solid var(--text-color);
	--bs-badge-padding-x: 0.88em;
    --bs-badge-padding-y: 0.68em;
	--bs-badge-font-family: "font-regular";
    --bs-badge-font-size: var(--font-size-md);
    --bs-badge-font-weight: normal;
}

.image-wrapper {
	width: 100%;
	max-width: 34px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.image-wrapper img {
	width: 100%;
	height: 100%;
}

.image-wrapper.xxs {
	max-width: 42px;
}

.image-wrapper.xs {
	max-width: 140px;
}

.image-wrapper.sm {
	max-width: 240px;
}

.image-wrapper.md {
	max-width: 310px;
}

.image-wrapper.lg {
	max-width: 400px;
}

.image-wrapper.xl {
	max-width: 540px;
}

.image-wrapper.xxl {
	max-width: 1200px;
}

.image-wrapper.full {
	max-width: 100%;
}

.image-wrapper.height {
	height: 100vh;
	max-height: 35px;
}

.image-wrapper.height img {
	height: 100%;
	width: auto;
}

.image-wrapper.height.xs {
	max-height: 140px;
}

.image-wrapper.height.sm {
	max-height: 240px;
}

.image-wrapper.height.md {
	max-height: 310px;
}

.image-wrapper.height.lg {
	max-height: 400px;
}

.image-wrapper.height.xl {
	max-height: 800px;
}

.image-wrapper.height.xxl {
	max-height: 1200px;
}

.image-wrapper.height.full {
	max-height: 100%;
}

.image-wrapper.xxs {
    max-width: 40px;
}

.image-wrapper.icon {
    max-width: 16.2px;
}

.image-wrapper.reverse {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

[data-lucide] {
    color: var(--text-color);
    flex-shrink: 0;
}

button {
    background-color: unset;
    padding: unset;
    outline: none;
    border: unset;
}

.p-content {
	padding: var(--padding);
}

.bg-decoration {
	background: url("../img/design/decoration-01.svg") no-repeat;
	background-size: 14rem;
	background-position: left top;
}

.bg-gray-light {
	background-color: #e3e3e326;
}

.bg-servers::before,
.bg-poly::before,
.bg-waves-in-column::before,
.bg-waves::before,
.bg-hands-on-laptop::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
}

.bg-waves:before {
	background-image: url('../img/design/waves.jpg');
    background-size: cover;
	background-position: center;
	filter: brightness(5.2) contrast(0.34) saturate(0.19) hue-rotate(1deg) brightness(1.48);
}

.bg-waves-in-column:before {
	background-image: url('../img/design/waves-in-column.jpg');
    background-size: cover;
	background-position: center;
	filter: brightness(2.8) contrast(0.22) saturate(0.1) hue-rotate(129deg) brightness(1.6);
}

.bg-hands-on-laptop::before {
	background-image: url('../img/design/hands-on-computer.jpg');
    background-size: cover;
	background-position: center -28rem;
    filter: brightness(50%);
}

.bg-poly:before {
	background-image: url('../img/design/poly.jpg');
    background-size: cover;
	background-position: center;
	filter: brightness(0.4);
}

.bg-servers:before {
	background-image: url('../img/design/servers.jpg');
    background-size: cover;
	background-position: center;
	filter: brightness(0.48);
}

.bg-servers > *,
.bg-poly > *,
.bg-hands-on-laptop > * {
	color: white;
}

.w-mc {
	width: max-content;
}

.h-mc {
	height: max-content;
}

.scroll-touch {
    overflow: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

.scroll-touch .scroll-content {
    flex-wrap: nowrap;
}

.box-info .content {
	width: 100%;
	max-width: 404px;
	position: absolute;
	left: 0;
	bottom: -0.5rem;
}

.list-group-item {
	border: unset;
	padding: unset;
}

/* ------------------------------ */
/* ENDING: DEFAULT STYLES */
/* ------------------------------ */

/* ------------------------------ */
/* STARTING: FORM STYLES */
/* ------------------------------ */

.form-control {
	padding: .6rem .84rem;
	border-color: var(--border-color);
}

/* ------------------------------ */
/* ENDING: FORM STYLES */
/* ------------------------------ */

/* ------------------------------ */
/* STARTING: BUTTONS STYLES */
/* ------------------------------ */

.btn {
    --bs-btn-border-radius: calc(var(--border-radius) + 2px);
    --bs-btn-color: var(--text-color);
    font-family: "font-medium";
    font-size: var(--font-size-md);
    transition: background-color 0.2s ease-in-out;
    text-transform: capitalize;
    text-align: center;
    text-wrap: nowrap;
}
 
 .btn-primary {
    --bs-btn-bg: var(--primary-color);
    --bs-btn-border-color: var(--primary-color);
    --bs-btn-hover-bg: var(--primary-color-hover);
    --bs-btn-hover-border-color: var(--primary-color-hover);
    --bs-btn-active-bg: var(--primary-color-active);
    --bs-btn-active-border-color: var(--primary-color-active);
}
 
 .btn-primary,
 .btn-primary * {
    color: #fff;
}

.btn-outline-primary {
	--bs-btn-border-color: var(--primary-color);
	--bs-btn-hover-bg: var(--primary-color);
	--bs-btn-hover-border-color: var(--primary-color);
	--bs-btn-active-bg: var(--primary-color-active);
	--bs-btn-active-border-color: var(--primary-color-active);
}
 
.btn-light {
	--bs-btn-bg: var(--primary-theme-color);
	--bs-btn-border-color: var(--primary-color);
	--bs-btn-hover-bg: var(--primary-color);
	--bs-btn-hover-border-color: var(--primary-color);
	--bs-btn-active-bg: var(--primary-color-active);
	--bs-btn-active-border-color: var(--primary-color-active);
}
 
 .btn-light.active {
    background-color: var(--primary-theme-color);
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color);
}
 
 .btn-light.active:hover {
    background-color: var(--primary-color-hover);
}
 
 .btn-light.active {
    --bs-btn-hover-border-color: var(--primary-color-hover);
}
 
 .btn-light:active *,
 .btn-light:hover * {
    color: #fff;
}
 
 .btn-white {
    background-color: var(--primary-theme-color);
}

.btn-group {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 0.8rem;
}

.btn-group > .btn {
	border-radius: var(--border-radius) !important;
	text-wrap: wrap;
}

.btn-arrow {
	gap: 1rem;
}

.btn-arrow .lucide {
	position: relative;
	transition: left 0.2s ease-in-out;
	top: 0.1rem;
}

.btn-arrow:hover .lucide {
	left: 0.3rem;
}

.tab-content>.active {
	display: flex;
}

/* ------------------------------ */
/* ENDING: BUTTONS STYLES */
/* ------------------------------ */

/* ------------------------------ */
/* STARTING: BOXES STYLES */
/* ------------------------------ */

.boxes {
    --bs-gutter-y: var(--content-padding);
    --bs-gutter-x: var(--content-padding);
}

.boxes .box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--content-padding);
    background-color: var(--primary-theme-color);
	background-color: unset;
    justify-content: start;
}

.boxes-toggle .box {
	width: 100%;
	min-width: 260px;
	padding: calc(var(--content-padding) * 1.1);
	border-bottom: 1.6px solid var(--border-color);
}

.boxes-toggle .box.active {
	border-color: var(--primary-color-active);
}

/* ------------------------------ */
/* ENDING: BOXES STYLES */
/* ------------------------------ */

/* ------------------------------ */
/* STARTING: ACCORDION STYLES */
/* ------------------------------ */

.accordion .accordion-item {
    background-color: transparent;
    border: unset;
} 

.accordion .accordion-item .accordion-body,
.accordion .accordion-button {
    background-color: var(--primary-theme-color);
    color: var(--text-color);
    box-shadow: unset;
}

.accordion .accordion-button {
    font-family: "font-medium";
    font-size: var(--font-size-lg);
}

.accordion .accordion-item .accordion-body {
    font-family: "font-regular";
    font-size: var(--font-size-md);
}

.accordion-separated .accordion-button[aria-expanded="true"] {
    border-color: var(--primary-color) !important;
}

.accordion-separated .accordion-button::after {
    all: unset;
}

.accordion-button {
	cursor: pointer;
    --bs-accordion-btn-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="%23374F56" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>');
    --bs-accordion-btn-active-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="%23374F56" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>');
}

.accordion-button:not(.collapsed) {
    color: var(--text-color);
    background-color: unset;
}

/* ------------------------------ */
/* ENDING: ACCORDION STYLES */
/* ------------------------------ */

/* ------------------------------ */
/* STARTING: CAROUSEL STYLES */
/* ------------------------------ */

.carousel {
	display: flex;
	flex-direction: column-reverse;
	gap: 28.8px;
}

.carousel-item {
	min-height: 344px;
}

.carousel-indicators {
	margin-bottom: unset;
	position: relative;
}

.carousel-indicators [data-bs-target] {
	width: 10.4px;
	height: 10.4px;
	background-color: var(--primary-color);
	border-radius: 100%;
	margin-right: 10px;
    margin-left: 10px;
}

/* ------------------------------ */
/* ENDING: CAROUSEL STYLES */
/* ------------------------------ */

/* ------------------------------ */
/* STARTING: HEADER STYLES */
/* ------------------------------ */

header .header-main {
	padding: 0.8rem 0;
}

header .header-main .navbar {
	--bs-navbar-nav-link-padding-x: 1.2rem;
	--bs-nav-link-font-size: var(--font-size-lg);
	--bs-nav-link-color: var(--text-color);
	font-family: 'font-medium';
}

header .header-main .navbar-nav {
	--bs-nav-link-color: var(--text-color);
	--bs-nav-link-hover-color: var(--primary-color-hover);
	--bs-nav-link-active-color: var(--primary-color-active);
	--bs-navbar-active-color: var(--secondary-color);
}

.header-main .navbar .dropdown-menu {
	--bs-dropdown-bg: var(--secondary-color);
	--bs-dropdown-color: #fff;
	--bs-dropdown-link-color: #fff;
    --bs-dropdown-link-active-bg: var(--primary-color);
    --bs-dropdown-padding-y: 0.6rem;
    --bs-dropdown-min-width: max-content;
    --bs-dropdown-spacer: 0.42rem;
}

.header-main .navbar .dropdown-menu .dropdown-item {
    font-family: "font-medium";
    font-size: var(--font-size-md);
    padding: 8px 18px;
	transition: unset;
}

header .header-main .dropdown-toggle::after {
    border: unset;
    vertical-align: unset;
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14.8" height="14.8" viewBox="0 0 24 24" fill="none" stroke="%23374F56" stroke-width="2.8" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>');
    transition: rotate 0.2s ease-in-out;
    cursor: pointer;
	margin-left: 0.44em;
	top: 0.1rem;
	position: relative;
}

header .header-main .dropdown-toggle.active::after,
header .header-main .dropdown-toggle:hover::after {
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14.8" height="14.8" viewBox="0 0 24 24" fill="none" stroke="%2357717d" stroke-width="2.8" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>');
}
 
header .header-main .dropdown-toggle:active::after,
header .header-main .dropdown-toggle:focus::after {
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14.8" height="14.8" viewBox="0 0 24 24" fill="none" stroke="%2357717d" stroke-width="2.8" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>');
}
 
header .header-main .dropdown-toggle[aria-expanded="true"]::after {
	top: -0.1rem;
    rotate: 180deg;
}

.dropdown-lang .image-wrapper {
    width: 18px;
    border-radius: 100%;
    overflow: hidden;
}

.dropdown-lang .dropdown-item strong {
	color: white;
}

.dropdown-lang .dropdown-item:focus strong,
.dropdown-lang .dropdown-item:active strong,
.dropdown-lang .dropdown-item:hover strong {
	color: var(--text-color);
}

.navbar-collapse {
	flex-basis: 0;
}

.navbar-toggler {
	display: none;
}

body.navbar-expanded header .header-main .navbar {
	display: flex;
}

/* ------------------------------ */
/* ENDING: HEADER STYLES */
/* ------------------------------ */

/* ------------------------------ */
/* STARTING: CONTRACT STYLES */
/* ------------------------------ */

.contract .contract-sidebar {
	border: 1px solid var(--primary-color);
	border-radius: var(--border-radius);
	padding: var(--padding);
}

.contract .contract-sidebar li a.active {
	color: var(--secondary-color) !important;
}

/* ------------------------------ */
/* ENDING: CONTRACT STYLES */
/* ------------------------------ */

/* ------------------------------ */
/* STARTING: RESPONSIVE STYLES */
/* ------------------------------ */

@media (max-width: 992px) {
	header .header-main .content {
		flex-wrap: wrap;
	}
	header .header-main .navbar:not(.show) {
		display: none;
	}
	header .header-main .navbar-toggler {
		display: flex;
	}
}

@media (max-width: 768px) {
	.box-info .content {
		max-width: 100%;
		position: relative;
	}
}

@media (max-width: 540px) {
	:root {
		--font-size-xl: calc(var(--font-size-xxs) * 1.58);
		--font-size-xxl: calc(var(--font-size-xxs) * 1.44);
	}
}

/* ------------------------------ */
/* ENDING: RESPONSIVE STYLES */
/* ------------------------------ */